.layout {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.layoutPadding {
  padding: 0 20px;
}

.layoutCenter {
  margin: auto;
}

.contentHeader {
  text-align: center;
  margin: 40px 0;
  padding-bottom: 30px;
  border-bottom: 1px solid var(--ion-color-grey);
}

.unstickFooter {
  margin-top: 0;
}
