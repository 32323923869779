@import 'src/styles/mixins';

.itemName {
  min-width: 0;
}

.itemQuantity {
  margin: 0 15px;
}

.list {
  margin: 15px -20px 45px;
}

.item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 20px;
  border-bottom: 1px solid var(--ion-color-grey);
}

.controlButtons {
  display: flex;
  align-items: center;
}

.componentBtnIcon {
  font-size: 14px;
}
