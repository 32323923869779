@import 'src/styles/mixins';

$img-width: 125px;

.itemCol {
  @include horizontal-stack-padding(5px, 2);
  @include vertical-stack-margin(7px, 7px);
  & > * {
    @include vertical-stack-margin;
  }
}

.listItem {
  --padding-top: 10px;
  --padding-bottom: 10px;
  --padding-start: 10px;
  --padding-end: 10px;
  --inner-padding-start: 0;
  --inner-padding-end: 0;
  border-radius: 6px;
  border-width: 1px;
  border-style: solid;
  border-color: var(--ion-color-light);
  @include vertical-stack-margin;
}

.selectedListItem {
  border-color: var(--ion-color-primary);
}

.listItemGrid {
  max-width: 100%;
}

.listItemInfo {
  max-width: calc(100% - #{$img-width});
  padding-left: 15px !important;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  > * {
    @include vertical-stack-margin;
  }
}

.itemDescription {
  display: block;
  width: 100%;
  @include multiline-text-truncate(3);

  @include desktop {
    margin-bottom: 5px !important;
  }
}

.itemImg {
  --size: #{$img-width};
  display: flex;
  align-items: center;
}

.itemImgPlaceholder {
  width: #{$img-width};
  height: auto;
  opacity: 0.5;
}

.editBtnsContainer {
  margin-top: auto;
}

.editBtn {
  @include horizontal-stack-margin;
  margin-top: 5px;
  margin-bottom: 5px;
  max-width: 100%;
}

.editBtnText {
  @include text-truncate;
}

.editBtnIcon {
  font-size: 17px;
}

.productEditBtn {
  --padding-start: 15px;
  --padding-end: 15px;
}
