@import 'src/styles/mixins';

.partialsContainer {
  margin: 10px 0;
}

.partialsTitle {
  margin-bottom: 5px;
}

.partialRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  @include vertical-stack-margin;
}

.partialRowText {
  @include text-truncate;
}

.partialRowPrice {
  margin-left: 10px;
}

.partialRowSign {
  margin-right: 5px;
  vertical-align: middle;
}

.originalPriceText {
  text-decoration: line-through;
  margin-right: 10px;
}
