@import 'src/styles/mixins';

.headerContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.vipList {
  margin: 10px 0;
}

.vipItem {
  border: 1px solid var(--ion-color-grey);
  border-radius: 6px;
  --padding-start: 15px;
  --padding-end: 15px;
  --padding-top: 15px;
  --padding-bottom: 15px;
  --inner-padding-start: 0;
  --inner-padding-end: 0;
  @include vertical-stack-margin(10px);
}
.vipItemContent {
  width: 100%;
}

.vipItemHeader {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.vipItemFooter {
  margin-top: 10px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.vipItemHeaderTexts {
  min-width: 0;
}
.vipItemTitle {
  @include text-truncate;
}
.vipItemDescription {
  margin-top: 5px;
  @include text-truncate;
}

.price {
  min-width: 100px;
  @include text-truncate;
}

.deleteBtn {
  min-width: 30px;
  height: 30px;
  margin-left: 10px;
}

.deleteBtnIcon {
  font-size: 14px;
}
