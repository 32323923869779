.tabBtn {
  --color: var(--ion-color-dark);
  --color-selected: var(--ion-color-primary);
}

.tabBtnText {
  color: var(--ion-color-medium);
}

.cartIcon {
  stroke: var(--ion-color-dark);
}

.cartPriceTag {
  position: absolute;
  right: -5px;
  top: 5px;
  padding: 3px;
  background-color: var(--ion-color-white);
  filter: drop-shadow(0px 2px 5px rgba(0, 0, 0, 0.15));
  border-radius: 88px;
  backdrop-filter: blur(10px);
}

.tabBtnClickArea {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0.2;
  margin-left: calc(-1 * #{var(--padding-start)});
  margin-right: calc(-1 * #{var(--padding-end)});
}
