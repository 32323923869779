@import 'src/styles/mixins';

.variantButtons {
  display: flex;
  margin: 5px 0;
  flex-wrap: wrap;
  justify-content: center;
}

.variantBtn {
  @include horizontal-stack-margin;
  margin-top: 5px;
  margin-bottom: 5px;
  max-width: 100%;
}

.variantBtnText {
  @include text-truncate;
}
