.modal {
  --max-width: 500px;
  --max-height: 520px;
  --border-radius: 20px;
}

.registrationFormModal {
  --height: 650px;
  --max-height: 650px;
}

.closeBtnContainer {
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 99;
}
