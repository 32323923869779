@import 'src/styles/mixins';

.tabBtn {
  min-width: 0;
  max-width: 100%;
  @include horizontal-stack-margin;
  margin-top: 5px;
  margin-bottom: 5px;
}

.tabBtnText {
  @include text-truncate;
}
