@import 'src/styles/mixins';

.content {
  margin: 20px 0;
  padding: 0 20px;
}

.form {
  margin-top: 30px;
}

.checkboxLabelText {
  vertical-align: middle;
}

.checkboxLabelLink {
  vertical-align: middle;
  margin-left: -10px;
  text-decoration: underline;
}
