.content {
  padding: 20px;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.header {
  margin-bottom: auto;
}
