.backBtn {
  height: auto;
  margin: 0;
  margin-left: -5px;
  --padding-start: 5px;
  --padding-end: 5px;
  --padding-top: 5px;
  --padding-bottom: 5px;
  --box-shadow: none;
}

.backBtnIcon {
  font-size: 25px;
  min-width: 25px;
}
