@import 'src/styles/mixins';

.popoverTitle {
  margin: 10px 16px 10px;
}

.pickerBtn {
  --border-width: 0;
  width: 40px;
  height: 40px;
}

.pickerBtnIcon {
  box-shadow: 0 0 5px 0 rgba(var(--ion-color-dark-rgb), 0.5);
  height: 18px;
  width: 24px;
}

.pickerItemContainer {
  display: flex;
  align-items: center;
  min-width: 0;
}

.pickerItemFlagIcon {
  box-shadow: 0 0 5px 0 rgba(var(--ion-color-dark-rgb), 0.5);
  min-width: 20px;
  height: 15px;
  margin-right: 10px;
}

.pickerItemText {
  @include text-truncate;
}

.pickerItemSelectedIcon {
  min-width: 24px;
  height: 24px;
}
