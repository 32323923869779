@import 'src/styles/mixins';

.content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  max-height: 100%;
  padding: 20px 0;
}

.logo {
  width: 90%;
  height: 55%;
  max-width: 200px;
  max-height: 122px;
}

.deliveringImg {
  width: 80%;
  height: 80%;
  max-width: 200px;
  max-height: 170px;
}

.btn {
  --border-color: var(--ion-color-success);
  --border-width: 0;
  max-width: 100%;
}

.btnText {
  @include text-truncate;
}

.btnIcon {
  min-width: 20px;
}
