.content {
  margin-top: 20px;
  margin-bottom: 20px;

  h1 {
    font-size: 28px;
    line-height: 1.4;
  }

  h2 {
    font-size: 24px;
    line-height: 1.4;
  }

  h3 {
    font-size: 20px;
    line-height: 1.4;
  }
}
