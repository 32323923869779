@import 'src/styles/mixins';

.container {
  margin-top: 25px;
}

.buttons {
  margin: 15px 0 40px;
}

.btn {
  margin-right: 5px;
  margin-top: 5px;
  max-width: 100%;
}

.btnText {
  @include text-truncate;
}

.popoverTitle {
  margin: 10px 16px 10px;
}

.timePopoverContainer {
  padding: 10px 0;
  display: flex;
  justify-content: space-around;
  max-height: calc(100% - 90px);
}

.timePopoverLabels {
  display: flex;
  justify-content: space-around;
}

.timePopoverList {
  overflow-y: scroll;
}

.timePopoverItem {
  --padding-top: 5px;
  --padding-bottom: 5px;
  --min-height: auto;
}
