.layout {
  padding: 0 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.centerContent {
  margin: auto;
}
