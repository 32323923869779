@import 'src/styles/mixins';

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 30px 0;
}

.label {
  @include text-truncate;
}

.value {
  margin-left: 10px;
}
