@import 'src/styles/mixins';

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin: 30px 0;
}

.saveBtn {
  max-width: 100%;
}

.saveBtnText {
  @include text-truncate;
}
