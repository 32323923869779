@import 'src/styles/mixins';

.content {
  margin: 60px 0 30px;
  text-align: center;
}

.container {
  text-align: center;
}

.columns {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.column {
  flex: 1;
  @include horizontal-stack-margin(30px);
}

.logo {
  margin: 0 auto;
  width: 100px;
  height: 64px;
}

.headerText {
  margin: 25px 0;
}

.storeBadges {
  margin: 10px 0;
}

.storeBadgeBtn {
  width: 50%;
  height: auto;
  --padding-top: 0;
  --padding-bottom: 0;
  --padding-start: 0;
  --padding-end: 0;
  margin-left: 0;
  margin-right: 0;
  --box-shadow: none;
  &:first-child {
    padding-right: 5px;
  }
  &:last-child {
    padding-left: 5px;
  }
}

.storeBadgeImg {
  width: 100%;
}
