@import 'src/styles/mixins';

.btn {
  min-width: 0;
  @include horizontal-stack-margin;

  @include desktop {
    --border-width: 0;
  }
}

.btnText {
  @include text-truncate;
}
