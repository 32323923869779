.arrowIcon {
  position: fixed;
  font-size: 30px;
  top: calc(50% - 15px);
  z-index: 10;
  pointer-events: none;
}

.backIcon {
  left: -5px;
}

.forwardIcon {
  right: -5px;
}
