.modal {
  --border-radius: 10px;
}

.content {
  margin-top: 30px;
}

.closeBtnContainer {
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 99;
}
