@import 'src/styles/mixins';

.container {
  margin: 10px 0;
  padding-bottom: 20px;
  border-bottom: 1px solid var(--ion-color-grey);
  text-align: center;

  & > * {
    @include vertical-stack-margin(10px);
  }
}

.btn {
  --border-color: var(--ion-color-success);
  --border-width: 0;
  max-width: 100%;
}

.btnText {
  @include text-truncate;
}

.btnIcon {
  min-width: 20px;
}
