@import 'src/styles/mixins';

.title {
  margin: 35px 0 10px;
  text-align: left;
}

.list {
  margin: 10px -20px 10px;
  padding: 0;
}

.listItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--ion-color-grey);
  --detail-icon-opacity: 1;
  --padding-start: 20px;
  --padding-end: 20px;
  --padding-top: 5px;
  --padding-bottom: 5px;
  --inner-padding-start: 0;
  --inner-padding-end: 0;
}

.listItemText {
  min-width: 0;
}
