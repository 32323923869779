/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

/** Ionic CSS Variables **/
:root {
  /** primary **/
  --ion-color-primary: #cc0000;
  --ion-color-primary-rgb: 204, 0, 0;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #b40000;
  --ion-color-primary-tint: #d11a1a;

  /** secondary **/
  --ion-color-secondary: #cc3300;
  --ion-color-secondary-rgb: 204, 51, 0;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #b42d00;
  --ion-color-secondary-tint: #d1471a;

  /** tertiary **/
  --ion-color-tertiary: #cc0066;
  --ion-color-tertiary-rgb: 204, 0, 102;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #b4005a;
  --ion-color-tertiary-tint: #d11a75;

  /** success **/
  --ion-color-success: #158d00;
  --ion-color-success-rgb: 21, 141, 0;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #127c00;
  --ion-color-success-tint: #2c981a;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #cc0000;
  --ion-color-danger-rgb: 204, 0, 0;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #b40000;
  --ion-color-danger-tint: #d11a1a;

  /** dark **/
  --ion-color-dark: #000000;
  --ion-color-dark-rgb: 0, 0, 0;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #000000;
  --ion-color-dark-tint: #1a1a1a;

  /** medium **/
  --ion-color-medium: #959595;
  --ion-color-medium-rgb: 149, 149, 149;
  --ion-color-medium-contrast: #000000;
  --ion-color-medium-contrast-rgb: 0, 0, 0;
  --ion-color-medium-shade: #838383;
  --ion-color-medium-tint: #a0a0a0;

  /** light **/
  --ion-color-light: #f1f1f1;
  --ion-color-light-rgb: 241, 241, 241;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d4d4d4;
  --ion-color-light-tint: #f2f2f2;

  /** white **/
  --ion-color-white: #ffffff;
  --ion-color-white-rgb: 255, 255, 255;
  --ion-color-white-contrast: #000000;
  --ion-color-white-contrast-rgb: 0, 0, 0;
  --ion-color-white-shade: #e0e0e0;
  --ion-color-white-tint: #ffffff;

  /** grey **/
  --ion-color-grey: #dcdcdc;
  --ion-color-grey-rgb: 220, 220, 220;
  --ion-color-grey-contrast: #000000;
  --ion-color-grey-contrast-rgb: 0, 0, 0;
  --ion-color-grey-shade: #c2c2c2;
  --ion-color-grey-tint: #e0e0e0;
}

/* Currently disabled. Needs serious rework for adjusting proper dark colors */
/*@media (prefers-color-scheme: dark) {*/
/*  !**/
/*   * Dark Colors*/
/*   * -------------------------------------------*/
/*   *!*/

/*  body {*/
/*    --ion-color-primary: #cc0000;*/
/*    --ion-color-primary-rgb: 204, 0, 0;*/
/*    --ion-color-primary-contrast: #ffffff;*/
/*    --ion-color-primary-contrast-rgb: 255, 255, 255;*/
/*    --ion-color-primary-shade: #b40000;*/
/*    --ion-color-primary-tint: #d11a1a;*/

/*    --ion-color-secondary: #cc3300;*/
/*    --ion-color-secondary-rgb: 204, 51, 0;*/
/*    --ion-color-secondary-contrast: #ffffff;*/
/*    --ion-color-secondary-contrast-rgb: 255, 255, 255;*/
/*    --ion-color-secondary-shade: #b42d00;*/
/*    --ion-color-secondary-tint: #d1471a;*/

/*    --ion-color-tertiary: #cc0066;*/
/*    --ion-color-tertiary-rgb: 204, 0, 102;*/
/*    --ion-color-tertiary-contrast: #ffffff;*/
/*    --ion-color-tertiary-contrast-rgb: 255, 255, 255;*/
/*    --ion-color-tertiary-shade: #b4005a;*/
/*    --ion-color-tertiary-tint: #d11a75;*/

/*    --ion-color-success: #158d00;*/
/*    --ion-color-success-rgb: 21, 141, 0;*/
/*    --ion-color-success-contrast: #ffffff;*/
/*    --ion-color-success-contrast-rgb: 255, 255, 255;*/
/*    --ion-color-success-shade: #127c00;*/
/*    --ion-color-success-tint: #2c981a;*/

/*    --ion-color-warning: #ffc409;*/
/*    --ion-color-warning-rgb: 255, 196, 9;*/
/*    --ion-color-warning-contrast: #000000;*/
/*    --ion-color-warning-contrast-rgb: 0, 0, 0;*/
/*    --ion-color-warning-shade: #e0ac08;*/
/*    --ion-color-warning-tint: #ffca22;*/

/*    --ion-color-danger: #cc0000;*/
/*    --ion-color-danger-rgb: 204, 0, 0;*/
/*    --ion-color-danger-contrast: #ffffff;*/
/*    --ion-color-danger-contrast-rgb: 255, 255, 255;*/
/*    --ion-color-danger-shade: #b40000;*/
/*    --ion-color-danger-tint: #d11a1a;*/

/*    --ion-color-dark: #ffffff;*/
/*    --ion-color-dark-rgb: 255, 255, 248;*/
/*    --ion-color-dark-contrast: #000000;*/
/*    --ion-color-dark-contrast-rgb: 0, 0, 0;*/
/*    --ion-color-dark-shade: #e0e0e0;*/
/*    --ion-color-dark-tint: #ffffff;*/

/*    --ion-color-medium: #6a6a6a;*/
/*    --ion-color-medium-rgb: 106, 106, 106;*/
/*    --ion-color-medium-contrast: #ffffff;*/
/*    --ion-color-medium-contrast-rgb: 255, 255, 255;*/
/*    --ion-color-medium-shade: #5d5d5d;*/
/*    --ion-color-medium-tint: #797979;*/

/*    --ion-color-light: #121212;*/
/*    --ion-color-light-rgb: 18, 18, 18;*/
/*    --ion-color-light-contrast: #ffffff;*/
/*    --ion-color-light-contrast-rgb: 255, 255, 255;*/
/*    --ion-color-light-shade: #101010;*/
/*    --ion-color-light-tint: #2a2a2a;*/

/*    --ion-color-white: #121212;*/
/*    --ion-color-white-rgb: 18, 18, 18;*/
/*    --ion-color-white-contrast: #ffffff;*/
/*    --ion-color-white-contrast-rgb: 255, 255, 255;*/
/*    --ion-color-white-shade: #101010;*/
/*    --ion-color-white-tint: #2a2a2a;*/

/*    --ion-color-grey: #232323;*/
/*    --ion-color-grey-rgb: 35, 35, 35;*/
/*    --ion-color-grey-contrast: #ffffff;*/
/*    --ion-color-grey-contrast-rgb: 255, 255, 255;*/
/*    --ion-color-grey-shade: #1f1f1f;*/
/*    --ion-color-grey-tint: #393939;*/
/*  }*/

/*  !**/
/*   * iOS Dark Theme*/
/*   * -------------------------------------------*/
/*   *!*/

/*  .ios body {*/
/*    --ion-background-color: #000000;*/
/*    --ion-background-color-rgb: 0, 0, 0;*/

/*    --ion-text-color: #ffffff;*/
/*    --ion-text-color-rgb: 255, 255, 255;*/

/*    --ion-color-step-50: #0d0d0d;*/
/*    --ion-color-step-100: #1a1a1a;*/
/*    --ion-color-step-150: #262626;*/
/*    --ion-color-step-200: #333333;*/
/*    --ion-color-step-250: #404040;*/
/*    --ion-color-step-300: #4d4d4d;*/
/*    --ion-color-step-350: #595959;*/
/*    --ion-color-step-400: #666666;*/
/*    --ion-color-step-450: #737373;*/
/*    --ion-color-step-500: #808080;*/
/*    --ion-color-step-550: #8c8c8c;*/
/*    --ion-color-step-600: #999999;*/
/*    --ion-color-step-650: #a6a6a6;*/
/*    --ion-color-step-700: #b3b3b3;*/
/*    --ion-color-step-750: #bfbfbf;*/
/*    --ion-color-step-800: #cccccc;*/
/*    --ion-color-step-850: #d9d9d9;*/
/*    --ion-color-step-900: #e6e6e6;*/
/*    --ion-color-step-950: #f2f2f2;*/

/*    --ion-item-background: #000000;*/

/*    --ion-card-background: #1c1c1d;*/
/*  }*/

/*  .ios ion-modal {*/
/*    --ion-background-color: var(--ion-color-step-100);*/
/*    --ion-toolbar-background: var(--ion-color-step-150);*/
/*    --ion-toolbar-border-color: var(--ion-color-step-250);*/
/*  }*/

/*  !**/
/*   * Material Design Dark Theme*/
/*   * -------------------------------------------*/
/*   *!*/

/*  .md body {*/
/*    --ion-background-color: #121212;*/
/*    --ion-background-color-rgb: 18, 18, 18;*/

/*    --ion-text-color: #ffffff;*/
/*    --ion-text-color-rgb: 255, 255, 255;*/

/*    --ion-border-color: #222222;*/

/*    --ion-color-step-50: #1e1e1e;*/
/*    --ion-color-step-100: #2a2a2a;*/
/*    --ion-color-step-150: #363636;*/
/*    --ion-color-step-200: #414141;*/
/*    --ion-color-step-250: #4d4d4d;*/
/*    --ion-color-step-300: #595959;*/
/*    --ion-color-step-350: #656565;*/
/*    --ion-color-step-400: #717171;*/
/*    --ion-color-step-450: #7d7d7d;*/
/*    --ion-color-step-500: #898989;*/
/*    --ion-color-step-550: #949494;*/
/*    --ion-color-step-600: #a0a0a0;*/
/*    --ion-color-step-650: #acacac;*/
/*    --ion-color-step-700: #b8b8b8;*/
/*    --ion-color-step-750: #c4c4c4;*/
/*    --ion-color-step-800: #d0d0d0;*/
/*    --ion-color-step-850: #dbdbdb;*/
/*    --ion-color-step-900: #e7e7e7;*/
/*    --ion-color-step-950: #f3f3f3;*/

/*    --ion-item-background: #1e1e1e;*/

/*    --ion-toolbar-background: #1f1f1f;*/

/*    --ion-tab-bar-background: #1f1f1f;*/

/*    --ion-card-background: #1e1e1e;*/
/*  }*/
/*}*/

.ion-color-white {
  --ion-color-base: var(--ion-color-white);
  --ion-color-base-rgb: var(--ion-color-white-rgb);
  --ion-color-contrast: var(--ion-color-white-contrast);
  --ion-color-contrast-rgb: var(--ion-color-white-contrast-rgb);
  --ion-color-shade: var(--ion-color-white-shade);
  --ion-color-tint: var(--ion-color-white-tint);
}

.ion-color-grey {
  --ion-color-base: var(--ion-color-grey);
  --ion-color-base-rgb: var(--ion-color-grey-rgb);
  --ion-color-contrast: var(--ion-color-grey-contrast);
  --ion-color-contrast-rgb: var(--ion-color-grey-contrast-rgb);
  --ion-color-shade: var(--ion-color-grey-shade);
  --ion-color-tint: var(--ion-color-grey-tint);
}
