@import 'src/styles/mixins';

.header {
  display: flex;
  align-self: start;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  margin-top: 30px;
}

.headerLeft,
.headerRight {
  display: flex;
}

.headerLeft {
  flex: 1 1 0;
  margin-right: 10px;
  min-width: 250px;
}

.headerRight {
  min-width: 0;
  flex-wrap: wrap;
}

.headerBtn {
  min-width: 0;
  @include horizontal-stack-margin;
}

.btn {
  --border-width: 0;
  min-width: 0;
  @include horizontal-stack-margin;
}

.btnText {
  @include text-truncate;
}

.btnIcon {
  min-width: 20px;
  margin-right: 10px;
}

.cartIcon {
  stroke: var(--ion-color-dark);
}

.cartItemsLengthText {
  margin-right: 10px;
}

.logo {
  min-width: 57px;
  min-height: 35px;
  cursor: pointer;
}

.logoBtn {
  height: auto;
  margin: 0;
  margin-left: -5px;
  margin-right: 35px;
  --padding-start: 5px;
  --padding-end: 5px;
  --padding-top: 5px;
  --padding-bottom: 5px;
  --box-shadow: none;
}
