@font-face {
  font-family: 'Playfair Display';
  src: url('../assets/fonts/PlayfairDisplay-Medium.eot'); /* IE9 Compat Modes */
  src: url('../assets/fonts/PlayfairDisplay-Medium.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../assets/fonts/PlayfairDisplay-Medium.otf') format('opentype'),
    /* Open Type Font */ url('../assets/fonts/PlayfairDisplay-Medium.svg') format('svg'),
    /* Legacy iOS */ url('../assets/fonts/PlayfairDisplay-Medium.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('../assets/fonts/PlayfairDisplay-Medium.woff') format('woff'),
    /* Modern Browsers */ url('../assets/fonts/PlayfairDisplay-Medium.woff2') format('woff2'); /* Modern Browsers */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Playfair Display Black';
  src: url('../assets/fonts/PlayfairDisplay-Black.eot'); /* IE9 Compat Modes */
  src: url('../assets/fonts/PlayfairDisplay-Black.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../assets/fonts/PlayfairDisplay-Black.otf') format('opentype'),
    /* Open Type Font */ url('../assets/fonts/PlayfairDisplay-Black.svg') format('svg'),
    /* Legacy iOS */ url('../assets/fonts/PlayfairDisplay-Black.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('../assets/fonts/PlayfairDisplay-Black.woff') format('woff'),
    /* Modern Browsers */ url('../assets/fonts/PlayfairDisplay-Black.woff2') format('woff2'); /* Modern Browsers */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Segoe UI';
  src: url('../assets/fonts/Segoe-UI.eot'); /* IE9 Compat Modes */
  src: url('../assets/fonts/Segoe-UI.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../assets/fonts/Segoe-UI.otf') format('opentype'),
    /* Open Type Font */ url('../assets/fonts/Segoe-UI.svg') format('svg'),
    /* Legacy iOS */ url('../assets/fonts/Segoe-UI.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('../assets/fonts/Segoe-UI.woff') format('woff'),
    /* Modern Browsers */ url('../assets/fonts/Segoe-UI.woff2') format('woff2'); /* Modern Browsers */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Segoe UI Bold';
  src: url('../assets/fonts/Segoe-UI-Bold.eot'); /* IE9 Compat Modes */
  src: url('../assets/fonts/Segoe-UI-Bold.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../assets/fonts/Segoe-UI-Bold.otf') format('opentype'),
    /* Open Type Font */ url('../assets/fonts/Segoe-UI-Bold.svg') format('svg'),
    /* Legacy iOS */ url('../assets/fonts/Segoe-UI-Bold.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('../assets/fonts/Segoe-UI-Bold.woff') format('woff'),
    /* Modern Browsers */ url('../assets/fonts/Segoe-UI-Bold.woff2') format('woff2'); /* Modern Browsers */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Lato Regular';
  src: url('../assets/fonts/Lato-Regular.eot'); /* IE9 Compat Modes */
  src: url('../assets/fonts/Lato-Regular.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../assets/fonts/Lato-Regular.otf') format('opentype'),
    /* Open Type Font */ url('../assets/fonts/Lato-Regular.svg') format('svg'),
    /* Legacy iOS */ url('../assets/fonts/Lato-Regular.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('../assets/fonts/Lato-Regular.woff') format('woff'),
    /* Modern Browsers */ url('../assets/fonts/Lato-Regular.woff2') format('woff2'); /* Modern Browsers */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Lato Bold';
  src: url('../assets/fonts/Lato-Bold.eot'); /* IE9 Compat Modes */
  src: url('../assets/fonts/Lato-Bold.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../assets/fonts/Lato-Bold.otf') format('opentype'),
    /* Open Type Font */ url('../assets/fonts/Lato-Bold.svg') format('svg'),
    /* Legacy iOS */ url('../assets/fonts/Lato-Bold.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('../assets/fonts/Lato-Bold.woff') format('woff'),
    /* Modern Browsers */ url('../assets/fonts/Lato-Bold.woff2') format('woff2'); /* Modern Browsers */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Lora Regular';
  src: url('../assets/fonts/Lora-Regular.eot'); /* IE9 Compat Modes */
  src: url('../assets/fonts/Lora-Regular.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../assets/fonts/Lora-Regular.otf') format('opentype'),
    /* Open Type Font */ url('../assets/fonts/Lora-Regular.svg') format('svg'),
    /* Legacy iOS */ url('../assets/fonts/Lora-Regular.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('../assets/fonts/Lora-Regular.woff') format('woff'),
    /* Modern Browsers */ url('../assets/fonts/Lora-Regular.woff2') format('woff2'); /* Modern Browsers */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Lora Bold';
  src: url('../assets/fonts/Lora-Bold.eot'); /* IE9 Compat Modes */
  src: url('../assets/fonts/Lora-Bold.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../assets/fonts/Lora-Bold.otf') format('opentype'),
    /* Open Type Font */ url('../assets/fonts/Lora-Bold.svg') format('svg'),
    /* Legacy iOS */ url('../assets/fonts/Lora-Bold.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('../assets/fonts/Lora-Bold.woff') format('woff'),
    /* Modern Browsers */ url('../assets/fonts/Lora-Bold.woff2') format('woff2'); /* Modern Browsers */
  font-weight: normal;
  font-style: normal;
}
