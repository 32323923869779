.qtyButtons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid var(--ion-color-grey);
  border-radius: 50px;
}

.qtyBtn {
  --border-width: 0;
  width: 50px;
  height: 50px;
}

.qtyBtnIcon {
  font-size: 14px;
}
