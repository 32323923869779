@import 'src/styles/mixins';

.stickyFooter {
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
  padding-left: 20px;
  padding-right: 20px;
  background-color: var(--ion-color-white);
  box-shadow: 0 -10px 10px 0px var(--ion-color-white);
  transform: translateZ(0);
  -webkit-transform: translateZ(0);
}

.container {
  text-align: right;
  margin-bottom: 10px;
}

.saveBtn {
  margin-top: 10px;
  --padding-end: 25px;
  --padding-start: 25px;
  --padding-top: 15px;
  --padding-bottom: 16px;
  overflow: hidden;
}
