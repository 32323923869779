@import 'src/styles/mixins';

.profileSection {
  margin: 20px auto;
}

.profileList {
  margin: 10px -20px 10px;
  padding: 0;
  border-top: 1px solid var(--ion-color-grey);
}

.profileListItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--ion-color-grey);
  --detail-icon-opacity: 1;
  --padding-start: 20px;
  --padding-end: 20px;
  --padding-top: 5px;
  --padding-bottom: 5px;
  --inner-padding-start: 0;
  --inner-padding-end: 0;
}

.profileListItemText {
  min-width: 0;
}

.stickLanguagePicker {
  position: absolute;
  right: 20px;
  top: 20px;
}
