@import 'src/styles/mixins';

.footer {
  text-align: center;
  margin-top: auto;
  padding: 0 20px 40px;
}

.footerLinks {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  & ion-button {
    min-width: 0;
    @include horizontal-stack-margin;
  }

  & ion-text {
    @include text-truncate;
  }
}

.socialIconButton {
  height: 60px;
  margin-bottom: 10px;
  --padding-top: 0;
  --padding-bottom: 0;
  --padding-start: 0;
  --padding-end: 0;
  --box-shadow: none;
}

.googleIosBadges {
  width: 120px;
  margin-bottom: 20px;
  --padding-top: 0;
  --padding-bottom: 0;
  --padding-start: 0;
  --padding-end: 0;
  --box-shadow: none;
}

.socialIcon {
  width: 60px;
  height: 60px;
}
