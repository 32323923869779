.mainModal {
  --border-radius: 10px;
  --width: 750px;
}

.editModal {
  --border-radius: 10px;
  --height: 80%;
  align-items: flex-end;
}

.content {
  margin-top: 50px;
}

.closeBtnContainer {
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 99;
}

.backBtnContainer {
  position: absolute;
  left: 20px;
  top: 20px;
  z-index: 99;
}
