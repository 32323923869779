@import 'src/styles/mixins';

.header {
  @include desktop {
    max-width: 500px;
    margin: 50px auto 40px;
  }
  @include mobile {
    margin: 40px 0 30px;
  }
}

.headerRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.headerMargin {
  @include vertical-stack-margin(40px);
}

.headerTitle {
  max-width: calc(100% - 50px);
}

.divider {
  margin: 30px 0;
  border-bottom: 1px solid var(--ion-color-grey);
}
