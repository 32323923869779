@import 'src/styles/mixins';

.sectionsWrapper {
  margin: 25px auto !important;
}

.section {
  @include vertical-stack-margin(20px);
}

.bankLogo {
  width: auto;
  height: 30px;
}

.bankBtn {
  @include horizontal-stack-margin;
  margin-top: 5px;
  margin-bottom: 5px;
}

.selectedBankBtn {
  --border-color: var(--ion-color-primary);
}

.confirmBtn {
  --padding-top: 17px;
  --padding-bottom: 21px;
  --padding-start: 25px;
}

.confirmBtnIcon {
  min-width: 20px;
  stroke: var(--ion-color-white);
}

.confirmBtnText {
  @include text-truncate;
}

.checkboxLabelText {
  vertical-align: middle;
}

.checkboxLabelLink {
  vertical-align: middle;
  margin-left: -10px;
  text-decoration: underline;
}

.imageWidthAuto {
  &::part(image) {
    width: auto;
  }
}
