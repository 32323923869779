@import 'src/styles/mixins';

.bonusPoints {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.bonusPointsHeader {
  display: flex;
  align-items: center;
}

.bonusPointsRefreshBtn {
  height: auto;
  margin: 0;
  margin-left: 5px;
  --padding-start: 5px;
  --padding-end: 5px;
  --padding-top: 5px;
  --padding-bottom: 5px;
  --box-shadow: none;
}

.bonusPointsRefreshBtnIcon {
  color: var(--ion-color-primary);
  font-size: 20px;
}

.bonusPointsTexts {
  text-align: left;
}

.bonusPointsValues {
  & > * {
    vertical-align: middle;
  }
}

.logo {
  min-width: 35px;
  min-height: 35px;
  border: 1px solid var(--ion-color-primary);
  border-radius: 25px;
  padding: 4px;
  margin-left: 10px;
}

.bonusPointsInfoLinkBtn {
  margin-left: -10px;
  text-decoration: underline;
}
