@mixin text-truncate {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

@mixin multiline-text-truncate($line-count: 3) {
  display: -webkit-box;
  -webkit-line-clamp: $line-count;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin stack-edges-nullifying($grid-size: 2, $offsetType: 'margin') {
  @if $grid-size < 2 {
    &:first-child {
      #{$offsetType}-left: 0;
    }
    &:last-child {
      #{$offsetType}-right: 0;
    }
  } @else {
    &:nth-child(#{$grid-size }n + 1) {
      #{$offsetType}-left: 0;
    }
    &:nth-child(#{$grid-size}n + #{$grid-size}) {
      #{$offsetType}-right: 0;
    }
  }
}

@mixin horizontal-stack-margin($margin: 5px, $grid-size: 1) {
  margin: 0 $margin;
  @include stack-edges-nullifying($grid-size, 'margin');
}

@mixin horizontal-stack-padding($padding: 5px, $grid-size: 1) {
  padding: 0 $padding;
  @include stack-edges-nullifying($grid-size, 'padding');
}

@mixin vertical-stack-margin($margin: 5px, $edge-offset: 0) {
  margin: $margin 0;
  &:first-child {
    margin-top: $edge-offset;
  }
  &:last-child {
    margin-bottom: $edge-offset;
  }
}

@mixin vertical-stack-padding($padding: 5px, $edge-offset: 0) {
  padding: $padding 0;
  &:first-child {
    padding-top: $edge-offset;
  }
  &:last-child {
    padding-bottom: $edge-offset;
  }
}

@mixin xl {
  @media screen and (min-width: 1200px) {
    @content;
  }
}

@mixin lg {
  @media screen and (min-width: 992px) and (max-width: 1199px) {
    @content;
  }
}

@mixin md {
  @media screen and (min-width: 768px) and (max-width: 991px) {
    @content;
  }
}

@mixin sm {
  @media screen and (min-width: 576px) and (max-width: 767px) {
    @content;
  }
}

@mixin xs {
  @media screen and (max-width: 575px) {
    @content;
  }
}

@mixin mobile {
  @media screen and (max-width: 767px) {
    @content;
  }
}

@mixin desktop {
  @media screen and (min-width: 768px) {
    @content;
  }
}

@mixin reset-button-styles {
  text-transform: initial;
  letter-spacing: initial;
  margin: 0;
  height: auto;
}

@mixin default-button-text {
  font-family: 'Lato Regular', serif;
  font-size: 14px;
  line-height: 17px;
}

@mixin default-text {
  font-family: 'Lato Regular', serif;
  font-size: 14px;
  line-height: 17px;
}
