.header {
  display: flex;
  align-self: start;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  margin-top: 50px;
}

.headerLeft,
.headerRight {
  display: flex;
}

.headerLeft {
  flex: 1 1 0;
  margin-right: 10px;
  min-width: 150px;
}

.headerEmpty {
  min-width: 0;
}

.headerRight {
  min-width: 0;
  flex-wrap: wrap;
}
