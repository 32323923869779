.closeBtn {
  height: auto;
  margin: 0;
  --padding-start: 4px;
  --padding-end: 4px;
  --padding-top: 4px;
  --padding-bottom: 4px;
  --box-shadow: none;
  --border-width: 1px;
  --border-style: solid;
  --border-color: var(--ion-color-grey);
}

.closeBtnIcon {
  font-size: 20px;
  min-width: 20px;
}
