@import 'src/styles/mixins';

.textReorderBtn {
  min-width: 0;
}

.textReorderBtnText {
  @include text-truncate;
}

.iconReorderBtn {
  min-width: 30px;
  height: 30px;
  margin-left: 10px;
}

.iconReorderBtnIcon {
  font-size: 14px;
}
