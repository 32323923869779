@import 'src/styles/mixins';

.stickyFooter {
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
  padding-left: 20px;
  padding-right: 20px;
  background-color: var(--ion-color-white);
  box-shadow: 0 -10px 10px 0px var(--ion-color-white);
  padding-bottom: var(--ion-safe-area-bottom);
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 10px;
}

.qtyPicker {
  margin-top: 10px;
}

.addToCartBtn {
  margin-top: 10px;
  --padding-end: 25px;
  --padding-start: 25px;
  --padding-top: 15px;
  --padding-bottom: 16px;
  overflow: hidden;
  display: none;
}

.longBtn {
  @media screen and (min-width: 400px) {
    display: block;
  }
}

.shortBtn {
  @media screen and (max-width: 399px) {
    display: block;
  }
}

.addToCartText {
  @include text-truncate;
}

.cartIcon {
  min-width: 20px;
  height: 20px;
  stroke: #ffffff;
}
