@import 'src/styles/mixins';

.categoriesLoader {
  margin-top: 10px;
  margin-bottom: 5px;
}
.categoriesLoaderRow {
  display: flex;
  overflow: hidden;
  @include vertical-stack-margin(10px);
}
.categoriesLoaderItem {
  height: 29px;
  border-radius: 6px;
  @include horizontal-stack-margin;
}

.productsLoader {
  padding: 8px 10px 5px;
  @include desktop {
    padding: 0;
    min-height: 300px;
    position: relative;
  }
}
.productsLoaderRow {
  display: flex;
  margin-bottom: 26px;
  &:first-child {
    margin-top: 11px;
  }
  &:last-child {
    margin-bottom: 11px;
  }
}
.productsLoaderTexts {
  width: calc(100% - 110px);
  margin-left: 15px;
}
.productsLoaderImage {
  width: 110px;
  height: 110px;
  margin: 0;
}
.productsLoaderTitle {
  width: 150px;
  height: 24px;
  margin: 0;
  margin-bottom: 10px;
}
.productsLoaderDescription {
  width: 100%;
  height: 14px;
}
.productsLoaderPrice {
  width: 70px;
  height: 17px;
  right: 0;
  left: calc(100% - 70px);
  margin: 0;
  margin-top: 10px;
}

.workingDaysLoaderItemLeft {
  width: 110px;
  height: 24px;
  margin: 0;
}

.workingDaysLoaderItemRight {
  width: 55px;
  height: 24px;
  margin: 0;
  right: 0;
  left: calc(100% - 55px);
}
