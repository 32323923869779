@import 'src/styles/mixins';

.content {
  padding: 20px;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.header {
  margin-bottom: auto;
}

.descriptions {
  margin: 0 auto 20px;
  max-width: 250px;
}

.description {
  @include vertical-stack-margin(30px);
}
