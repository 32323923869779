@import 'src/styles/mixins';

.content {
  margin: 20px 0;
}

.thankYouLogo {
  width: 131px;
  height: 141px;
  margin: 0 auto;
}

.detailsSection {
  @include vertical-stack-margin(30px);
}

.orderInfoRow {
  @include vertical-stack-margin(10px);
}

.orderInfoTitle {
  vertical-align: middle;
}

.orderInfoIcon {
  vertical-align: middle;
  margin-right: 10px;
}
