@import 'src/styles/mixins';

.header {
  margin: 50px 0 5px;
  display: flex;
}

.headerTitle {
  flex: 1;
  text-align: center;
  @include text-truncate;
  margin: 0 5px;
}

.hiddenPlaceholderBackBtn {
  visibility: hidden;
  opacity: 0;
}
