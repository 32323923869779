@import 'src/styles/mixins';

.categoryBtn {
  @include horizontal-stack-margin;
  margin-top: 5px;
  margin-bottom: 5px;
  max-width: 100%;
}

.categoryBtnText {
  @include text-truncate;
}

.list {
  margin: 5px 0 45px;

  @include mobile {
    margin-left: -20px;
    margin-right: -20px;
  }
}

.item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 0;
  border-bottom: 1px solid var(--ion-color-grey);

  @include mobile {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.addOnBtnIcon {
  font-size: 14px;
}

.itemPrice {
  vertical-align: middle;
  margin-right: 15px;
}

.itemAddBtn {
  vertical-align: middle;
}
