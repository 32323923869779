@import 'src/styles/mixins';

.ordersList {
  margin: 10px 0;
}

.orderListItem {
  border: 1px solid var(--ion-color-grey);
  border-radius: 6px;
  --padding-start: 15px;
  --padding-end: 15px;
  --padding-top: 10px;
  --padding-bottom: 10px;
  --inner-padding-start: 0;
  --inner-padding-end: 0;
  @include vertical-stack-margin(10px);
}

.itemContent {
  width: 100%;
}

.itemHeader {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.itemHeaderTexts {
  min-width: 0;
}

.itemTitle {
  margin-bottom: 5px;
  @include text-truncate;
}

.itemDescription {
  margin: 5px 0;
  @include text-truncate;
}

.itemFooter {
  margin-top: 10px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
