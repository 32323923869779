@import 'src/styles/mixins';

.item {
  padding-left: 20px;
  padding-right: 20px;
  border-bottom: 1px solid var(--ion-color-grey);

  @include desktop {
    padding-left: 0;
    padding-right: 0;
  }
}

.itemContent {
  flex: 1;
  padding: 10px 0;
  width: 100%;
}

.itemHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.itemHeaderText {
  @include text-truncate;
}

.itemHeaderPrice {
  white-space: nowrap;
}

.rightHeader {
  display: flex;
  align-items: center;
  margin-left: 10px;
  > * {
    @include horizontal-stack-margin;
  }
}

.originalPriceText {
  text-decoration: line-through;
  margin-right: 10px;
}
