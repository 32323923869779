@import './fonts';
@import './mixins';
@import './dokobit-overwrites';
@import './swiper-overwrites';
@import './reset';

.button-disabled {
  cursor: default;
  opacity: 0.2;
  pointer-events: none;
}

ion-popover .popover-viewport {
  display: contents !important;
}
