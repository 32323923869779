@import '../styles/mixins';

.item {
  padding-left: 20px;
  padding-right: 20px;
  border-bottom: 1px solid var(--ion-color-grey);

  @include desktop {
    padding-left: 0;
    padding-right: 0;
  }
}

.itemContent {
  flex: 1;
  padding: 10px 0;
  width: 100%;
}

.itemHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.itemHeaderText {
  @include text-truncate;
}

.itemHeaderPrice {
  margin-left: 10px;
}

.productsContainer {
  margin: 10px 0;
}

.productTitle {
  margin-bottom: 5px;
}

.productRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  @include vertical-stack-margin;
}

.productRowPartials {
  margin-left: 10px;
}

.productRowText {
  @include text-truncate;
}

.productRowPrice {
  margin-left: 10px;
}

.productRowSign {
  margin-right: 5px;
  vertical-align: middle;
}
