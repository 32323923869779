@import 'src/styles/mixins';

$img-width: 110px;

.desktopListItemCol {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  @include vertical-stack-margin(10px, 10px);
  @include md {
    @include horizontal-stack-padding(10px, 2);
  }
  @include lg {
    @include horizontal-stack-padding(10px, 3);
  }
  @include xl {
    @include horizontal-stack-padding(10px, 4);
  }
  & > * {
    @include vertical-stack-margin;
  }
  outline: none;
}

.desktopListItemColClickable {
  cursor: pointer;
}

.mobileListItem {
  --padding-top: 10px;
  --padding-bottom: 10px;
  --padding-start: 10px;
  --padding-end: 10px;
  --inner-padding-start: 0;
  --inner-padding-end: 0;
  border-radius: 6px;
  border-width: 1px;
  border-style: solid;
  border-color: var(--ion-color-light);
  @include vertical-stack-margin;
  outline: none;
}

.mobileListItemGrid {
  max-width: 100%;
}

.mobileListItemInfo {
  max-width: calc(100% - #{$img-width});
  padding-left: 15px !important;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  > * {
    @include vertical-stack-margin;
  }
}
