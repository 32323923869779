@import 'src/styles/mixins';

.warningHeader {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}
.warningTitle {
  @include text-truncate;
  max-width: 250px;
}

.clockIcon {
  margin-right: 10px;
}

.closeBtn {
  height: auto;
  margin: 0;
  margin-left: auto;
  --padding-start: 0;
  --padding-end: 0;
  --padding-top: 0;
  --padding-bottom: 0;
  --box-shadow: none;
}
