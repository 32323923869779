@import 'src/styles/mixins';

.addToCartContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 100%;
  & > * {
    @include horizontal-stack-margin;
  }
}

.addToCartBtn {
  --padding-end: 20px;
  --padding-start: 20px;
  --padding-top: 6px;
  --padding-bottom: 6px;
  --border-radius: 6px;
  max-width: 100%;
  min-width: 0;
}

.addToCartText {
  @include text-truncate;
}

.cartIcon {
  min-width: 20px;
  height: 20px;
  stroke: #ffffff;
}
