@import 'src/styles/mixins';

.contactButtons {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 20px 0;
}

.contactBtn {
  @include horizontal-stack-margin;
  @include text-truncate;
  margin-top: 5px;
  margin-bottom: 5px;
  --padding-top: 18px;
  --padding-bottom: 22px;
  --border-radius: 10px;
  --border-width: 1px;
}

.contactBtnText {
  @include text-truncate;
}

.contactBtnIcon {
  font-size: 24px;
  min-width: 24px;
}

.phoneBtn {
  min-width: 66px;
  flex: 1;
}
