@import 'src/styles/mixins';

.title {
  @include text-truncate;
}

.description {
  display: block;
  width: 100%;
  @include multiline-text-truncate(3);

  @include desktop {
    margin-bottom: 15px !important;
  }
}

.price {
  display: block;
  width: 100%;
  margin-top: auto !important;
  @include text-truncate;

  @include desktop {
    text-align: center;
  }

  @include mobile {
    text-align: right;
  }
}
