@import 'src/styles/mixins';

.container {
  padding: 0 20px;

  ion-grid,
  ion-row,
  ion-col,
  ion-searchbar {
    padding-left: 0;
    padding-right: 0;
  }
}

.header {
  margin-top: 35px;
}

.searchbar {
  --background: var(--ion-color-light);
  --clear-button-color: var(--ion-color-medium);
  --icon-color: var(--ion-color-dark);
  position: relative;
  margin-top: 20px;
  padding-left: 5px;
  padding-right: 5px;
}

.searchbarBackgroundExtender {
  background-color: var(--ion-color-light);
  border-radius: 10px;
  position: absolute;
  top: 7px;
  bottom: 7px;
  left: 0;
  right: 0;
  z-index: -1;
  pointer-events: none;
}

.list {
  padding: 10px 20px 20px;
  margin: 0 -20px;
}

.listItem {
  --padding-top: 5px;
  --padding-bottom: 5px;
  border-radius: 10px;
  border-width: 1px;
  border-style: solid;
  border-color: var(--ion-color-light);
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.07);
  @include vertical-stack-margin(10px);

  p {
    margin: 5px 0;
  }
}

.listItemDisabled {
  --background: var(--ion-color-grey);
}
