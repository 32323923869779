@import 'src/styles/mixins';

.content {
  @include desktop {
    margin-bottom: 30px;
  }
  @include mobile {
    margin-bottom: 20px;
  }
}

.listHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 0;
  margin-bottom: 15px;
}

.listHeaderTitle {
  @include text-truncate;
  flex: 1;
  min-width: 150px;
  margin-right: 10px;
}

.list {
  margin: 0 -20px 10px;
  padding: 0;
  border-top: 1px solid var(--ion-color-grey);

  @include desktop {
    margin-left: 0;
    margin-right: 0;
  }
}

.detailsSection {
  @include vertical-stack-margin(30px);
}

.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.footerText {
  @include text-truncate;
}

.footerPrice {
  margin-left: 10px;
}
