@import './mixins';

.container {
  margin: 0 auto;
  @include xs {
    width: 100%;
  }
  @include sm {
    width: 540px;
  }
  @include md {
    width: 720px;
  }
  @include lg {
    width: 960px;
  }
  @include xl {
    width: 1140px;
  }
}

.containerMedium {
  margin: 0 auto;
  @include xs {
    width: 100%;
  }
  @include sm {
    width: 500px;
  }
  @include md {
    width: 500px;
  }
  @include lg {
    width: 500px;
  }
  @include xl {
    width: 500px;
  }
}

.containerSmall {
  margin: 0 auto;
  @include xs {
    width: 100%;
    max-width: 350px;
  }
  @include sm {
    width: 350px;
  }
  @include md {
    width: 350px;
  }
  @include lg {
    width: 350px;
  }
  @include xl {
    width: 350px;
  }
}
