@import 'src/styles/mixins';

.container {
  @include vertical-stack-margin(20px);
  position: relative;
}

.label {
  position: absolute;
  top: calc(50% - 12px);
  left: 20px;
  max-width: calc(100% - 40px);
  color: var(--ion-color-medium);
  pointer-events: none;
  transform-origin: left;
  background-color: white;
  transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  @include text-truncate;

  &Static {
    transform: translateY(calc(-100% - 10px)) scale(0.75);
  }
}

.input {
  position: relative;
  box-shadow: 0 0 0 1px var(--ion-color-grey);
  border-radius: 10px;
  --padding-start: 20px;
  --padding-end: 20px;
  --padding-top: 20px;
  --padding-bottom: 20px;
  --placeholder-color: var(--ion-color-medium);
  &:hover {
    box-shadow: 0 0 0 2px var(--ion-color-medium);
  }
  &:focus-within {
    box-shadow: 0 0 0 2px var(--ion-color-success);
    .label {
      color: var(--ion-color-success);
      transform: translateY(calc(-100% - 10px)) scale(0.75);
    }
  }
  &HasValue {
    .label {
      transform: translateY(calc(-100% - 10px)) scale(0.75);
    }
  }
}

.errorInput {
  box-shadow: 0 0 0 2px var(--ion-color-danger) !important;
  .label {
    color: var(--ion-color-danger) !important;
  }
}

.disabledInput {
  box-shadow: 0 0 0 1px rgba(var(--ion-color-grey-rgb), 0.5);
  &:hover {
    box-shadow: 0 0 0 1px rgba(var(--ion-color-grey-rgb), 0.5);
  }
  .label {
    color: var(--ion-color-grey) !important;
  }
}

.errorText {
  display: block;
  color: var(--ion-color-danger);
  margin-left: 10px;
  margin-top: 5px;
  @include text-truncate;
}
