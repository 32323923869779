@import 'src/styles/mixins';

.modal {
  --height: 300px;
  @include desktop {
    --max-width: 500px;
  }
  @include mobile {
    align-items: flex-end;
  }
  --border-radius: 20px;
}

.closeBtnContainer {
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 99;
}
