@import 'src/styles/mixins';

.headerL {
  font-family: 'Lora Regular', serif;
  font-size: 28px !important;
  line-height: 37px !important;
  &Bold {
    font-family: 'Lora Bold', serif;
    font-size: 28px !important;
    line-height: 37px !important;
  }
}

.headerM {
  font-family: 'Lora Regular', serif;
  font-size: 18px !important;
  line-height: 24px !important;
  &Bold {
    font-family: 'Lora Bold', serif;
    font-size: 18px !important;
    line-height: 24px !important;
  }
}

.headerS {
  font-family: 'Lora Regular', serif;
  font-size: 14px !important;
  line-height: 19px !important;
  &Bold {
    font-family: 'Lora Bold', serif;
    font-size: 14px !important;
    line-height: 19px !important;
  }
}

.title {
  font-family: 'Lato Regular', serif;
  font-size: 20px !important;
  line-height: 24px !important;
  &Bold {
    font-family: 'Lato Bold', serif;
    font-size: 20px !important;
    line-height: 24px !important;
  }
}

.textL {
  font-family: 'Lato Regular', serif;
  font-size: 16px !important;
  line-height: 19px !important;
  &Bold {
    font-family: 'Lato Bold', serif;
    font-size: 16px !important;
    line-height: 19px !important;
  }
}

.textM {
  font-family: 'Lato Regular', serif;
  font-size: 14px !important;
  line-height: 17px !important;
  &Bold {
    font-family: 'Lato Bold', serif;
    font-size: 14px !important;
    line-height: 17px !important;
  }
}

.textS {
  font-family: 'Lato Regular', serif;
  font-size: 12px !important;
  line-height: 14px !important;
  &Bold {
    font-family: 'Lato Bold', serif;
    font-size: 12px !important;
    line-height: 14px !important;
  }
}
