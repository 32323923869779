.imgThumbNail {
  position: relative;
}

.imgHidden {
  visibility: hidden;
  position: absolute;
  top: 0;
}

.imgPlaceholder {
  width: 100%;
  height: 100%;
}
