@import 'src/styles/mixins';

.btn {
  min-width: 0;
  @include horizontal-stack-margin;
}

.btnPulsing {
  animation: pulse 1s infinite;
}

.btnText {
  @include text-truncate;
}

.btnIcon {
  min-width: 20px;
}

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(204, 0, 0, 0.4);
    border-radius: 100px;
  }
  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(204, 0, 0, 0);
    border-radius: 100px;
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(204, 0, 0, 0);
    border-radius: 100px;
  }
}
@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(204, 0, 0, 0.4);
    box-shadow: 0 0 0 0 rgba(204, 0, 0, 0.4);
    border-radius: 100px;
  }
  70% {
    -moz-box-shadow: 0 0 0 10px rgba(204, 0, 0, 0);
    box-shadow: 0 0 0 10px rgba(204, 0, 0, 0);
    border-radius: 100px;
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(204, 0, 0, 0);
    box-shadow: 0 0 0 0 rgba(204, 0, 0, 0);
    border-radius: 100px;
  }
}
