@import 'src/styles/mixins';

.header {
  text-align: center;
  margin: 40px 0;
  padding-bottom: 30px;
  border-bottom: 1px solid var(--ion-color-light);
}

.desktopContainer {
  ion-grid,
  ion-col {
    padding: 0;
  }

  ion-col {
    @include horizontal-stack-padding(30px);
  }
}

.salePointHeader {
  margin-top: 10px;
  padding-bottom: 35px;
  border-bottom: 1px solid var(--ion-color-light);
}

.salePointHeaderName {
  margin-top: 15px;
}

.cartColumn {
  max-width: 50%;
}
