@import 'src/styles/mixins';

.container {
  margin: 25px 0;
}

.confirmBtn {
  --padding-top: 17px;
  --padding-bottom: 21px;
  --padding-start: 25px;
}

.confirmBtnIcon {
  min-width: 20px;
  stroke: var(--ion-color-white);
}

.confirmBtnText {
  @include text-truncate;
}
