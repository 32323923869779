@import 'src/styles/mixins';

.outlineBtn {
  @include default-button-text;
  @include reset-button-styles;
  --border-width: 1px;
  --border-style: solid;
  --border-color: var(--ion-color-grey);
  --padding-top: 10px;
  --padding-bottom: 10px;
  --padding-start: 20px;
  --padding-end: 20px;
  --box-shadow: none;
}

.linkBtn {
  @include default-button-text;
  @include reset-button-styles;
  --border-width: 0;
  --padding-top: 5px;
  --padding-bottom: 5px;
  --padding-start: 10px;
  --padding-end: 10px;
  --box-shadow: none;
}

.primaryBtn {
  @include default-button-text;
  @include reset-button-styles;
  --border-width: 1px;
  --border-radius: 10px;
  --padding-top: 19px;
  --padding-bottom: 23px;
  --padding-start: 10px;
  --padding-end: 10px;
  --box-shadow: none;

  &::part(native) {
    line-height: 24px;
  }
}

.chipBtn {
  @include default-button-text;
  @include reset-button-styles;
  --border-radius: 6px;
  --padding-top: 6px;
  --padding-bottom: 6px;
  --padding-start: 15px;
  --padding-end: 15px;
  --box-shadow: none;

  &::part(native) {
    line-height: 17px;
  }

  @include desktop {
    --padding-start: 30px;
    --padding-end: 30px;
  }
}

.controlBtn {
  @include default-button-text;
  @include reset-button-styles;
  width: 32px;
  height: 32px;
  --padding-top: 0;
  --padding-bottom: 0;
  --padding-start: 0;
  --padding-end: 0;
  --border-width: 1px;
  --border-style: solid;
  --border-color: var(--ion-color-grey);
  --box-shadow: none;
}

.checkboxContainer {
  @include vertical-stack-margin(15px);
  > * {
    @include horizontal-stack-margin;
    vertical-align: middle;
  }
}
