.container {
  position: relative;
  padding: 5px;
  border-radius: 4px;
  border-bottom-right-radius: 0;
  white-space: nowrap;
  cursor: pointer;
  user-select: none;
  &Disabled {
    cursor: default;
  }
  background-color: white;
}

.disabledText {
  color: var(--ion-color-medium);
}

.earnBonusesLabel {
  position: absolute;
  bottom: -20px;
  background-color: var(--ion-color-primary);
  color: white;
  right: -1px;
  padding: 3px 15px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  cursor: default;
  pointer-events: none;
  z-index: -1;

  &Hidden {
    bottom: 0;
  }

  transition: bottom ease 0.2s;
}
