@import 'src/styles/mixins';

.btn {
  @include desktop {
    margin-right: -10px;
  }
  @include mobile {
    margin-left: -10px;
  }
}
