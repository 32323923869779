#scrollToTopButton {
  position: fixed;
  bottom: 10vh;
  right: 8vw;
  border-radius: 50%;
  height: 40px;
  backdrop-filter: blur(1px);
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: pulse 1.5s linear forwards;
  cursor: pointer;

  > * {
    width: 80%;
    height: 80%;
  }
}

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(204, 0, 0, 0.4);
  }
  50% {
    -webkit-box-shadow: 0 0 0 10px rgba(204, 0, 0, 0);
  }
  70% {
    -webkit-box-shadow: 0 0 0 0 rgba(204, 0, 0, 0);
  }
  100% {
    -webkit-box-shadow: 4px 4px 15px rgb(99, 98, 98);
  }
}
@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(204, 0, 0, 0.4);
    box-shadow: 0 0 0 0 rgba(204, 0, 0, 0.4);
  }
  50% {
    -moz-box-shadow: 0 0 0 10px rgba(204, 0, 0, 0);
    box-shadow: 0 0 0 10px rgba(204, 0, 0, 0);
  }
  70% {
    -moz-box-shadow: 0 0 0 0 rgba(204, 0, 0, 0);
    box-shadow: 0 0 0 0 rgba(204, 0, 0, 0);
  }
  100% {
    -moz-box-shadow: 4px 4px 15px rgb(99, 98, 98);
    box-shadow: 4px 4px 15px rgb(99, 98, 98);
  }
}
