@import 'src/styles/mixins';

.content {
  margin: 10px 0;
}

.list {
  margin: 0 -20px 10px;
  padding: 0;
  list-style: none;

  @include desktop {
    margin-left: 0;
    margin-right: 0;
  }
}

.itemQty {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

.originalPriceText {
  text-decoration: line-through;
  margin-right: 10px;
}
