.iosSafeAreaOffset {
  height: var(--ion-safe-area-top);
}

.iosSafeAreaPlaceholder {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: var(--ion-safe-area-top);
  background-color: var(--ion-color-white);
  z-index: 100;
}
