.header {
  margin-bottom: 35px;
}

.img {
  display: flex;
  align-items: center;
  margin: 20px auto 10px;
  --size: 220px;
}
