@import './mixins';

.swiper-pagination-bullet-active {
  background-color: var(--ion-color-primary) !important;
  opacity: 1 !important;
}

.swiper-button-prev {
  color: var(--ion-color-white) !important;
}

.swiper-button-next {
  color: var(--ion-color-white) !important;
}
