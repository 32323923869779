@import 'src/styles/mixins';

.campaigns {
  margin: 25px 0 25px;
  position: relative;

  @include mobile {
    margin: 10px 0 25px;
  }
  @include mobile {
    --swiper-navigation-size: 25px;
  }
}

.focusAreaBtn {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  pointer-events: none;
  opacity: 0;
}

.swiperBullet {
  width: 10px !important;
  height: 10px !important;
  margin: 0 5px !important;
  opacity: 0.5 !important;
}

.swiperImg {
  width: 100%;
  height: auto;
  border-radius: 10px;
  cursor: pointer;

  // TODO: Find out if desktop swiper should have fixed height or not.
  //@include xl {
  //  height: 300px;
  //}
  //
  //@include lg {
  //  height: 252px;
  //}
  //
  //@include lg {
  //  height: 252px;
  //}
  //
  //@include md {
  //  height: 190px;
  //}
  //
  //@include sm {
  //  height: auto;
  //}
  //
  //@include xs {
  //  height: auto;
  //}
}
