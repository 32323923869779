@import 'src/styles/mixins';

.categoryBtnContainer {
  margin-left: -5px;
}

.categoryBtn {
  margin: 5px;
  max-width: 100%;
}

.categoryBtnText {
  @include text-truncate;
}

.itemNameContainer {
  display: flex;
  align-items: center;
  min-width: 0;
}

.selectedItemPlusSign {
  margin-right: 15px;
}

.list {
  margin: 5px -20px 45px;
}
