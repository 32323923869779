@import 'src/styles/mixins';
@import 'src/components/menu/Products.module';

.img {
  --size: #{$img-width};
  display: flex;
  align-items: center;

  @include desktop {
    --size: 220px;
  }
}

.imgPlaceholder {
  height: auto;
  opacity: 0.5;

  @include desktop {
    width: 220px;
  }
}
