@import 'src/styles/mixins';

.stickyWrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  max-width: 100%;
  z-index: 10;
  background-color: var(--ion-color-white);
  box-shadow: 0 10px 10px 0px var(--ion-color-white);
}

.stickyContainer {
  @include xs {
    padding: 0 20px;
  }
}

.scrollBarOffset {
  right: 17px;
}

.categories {
  padding-top: 5px;
}

.categoryButtons {
  display: flex;
}

.categoryButtonsMargin {
  margin: 5px 0;
}

.categoryBtn {
  @include horizontal-stack-margin;
}

.hideScrollBar {
  overflow: auto;
  &::-webkit-scrollbar {
    display: none;
  }
}
